<template>
    <div id="secure">
        <h1>PAW</h1>
        <table v-if="paw" id="table">
            <!--
            <tr>
                <th>PAW-ID</th>
                <td>{{ paw.id }}</td>
            </tr>
            -->
            <tr>
                <th>Bezeichnung</th>
                <td>{{ paw.description }}</td>
            </tr>
            <tr>
                <th>Name</th>
                <td>{{ paw.forename }} {{ paw.name }}</td>
            </tr>
            <tr>
                <th>Geburtstag</th>
                <td>{{ dateFormat(paw.birthDate, "myDate") }}</td>
            </tr>
            <tr>
                <th>Geschlecht</th>
                <td>{{ gender }}</td>
            </tr>
            <tr>
                <th>Gewicht</th>
                <td v-if="paw.weight && paw.weight.toString().length > 0">{{ paw.weight }} kg</td>
                <td v-else>nicht angegeben</td>
            </tr>
            <tr>
                <th>Größe</th>
                <td v-if="paw.height && paw.height.toString().length > 0">{{ paw.height }} cm</td>
                <td v-else>nicht angegeben</td>
            </tr>
            <tr>
                <th>Schuhgröße</th>
                <td v-if="paw.shoeSize && paw.shoeSize.toString().length > 0">{{ paw.shoeSize }}</td>
                <td v-else>nicht angegeben</td>
            </tr>
            <tr>
                <th>Anmerkungen</th>
                <td>{{ commentHTML }}</td>
            </tr>
            <tr>
                <th>Lieferadresse</th>
                <td>
                    {{ paw.deliveryAddress.street }}
                    <br>
                    {{ paw.deliveryAddress.postCode }}
                    {{ paw.deliveryAddress.city }}
                    <br>
                    {{ paw.deliveryAddress.country }}
                    <br>
                </td>
            </tr>
            <!--
            <tr>
                <th>Stand</th>
                <td>
                    <pre>{{ paw.lastChange.toLocaleDateString() }} {{ paw.letzte_aenderung.toLocaleTimeString() }}</pre>
                </td>
            </tr>-->
            <tr v-if="leftExists">
                <th>
                    Bilder links
                    <div v-if="editable">
                        <br>
                        <md-button class="md-raised md-accent" v-on:click="editImages(0)">Bearbeiten</md-button>
                    </div>
                    <div v-if="leftPPS.status === PDFStatus.ready" style="font-weight: normal">
                        <br>
                        PDF in Arbeit
                    </div>
                    <div v-if="leftPPS.status === PDFStatus.done">
                        <br>
                        <md-button class="md-raised md-accent" v-on:click="getPDF(leftPPS)">PDF</md-button>
                    </div>
                    <div v-if="leftPPS.status === PDFStatus.error" style="font-weight: normal">
                        <br>
                        PDF Fehler
                    </div>
                </th>
                <td>
                    <table class="foot_images">
                        <tr>
                            <th>mit Fuß</th>
                            <th>ohne Fuß</th>
                        </tr>
                        <tr>
                            <td>
                                <img :src="emptyImg + leftPPS.feet" alt="Bild links mit Fuß"
                                     v-on:click="imageDetails(emptyImg + leftPPS.feet)">
                            </td>
                            <td>
                                <img :src="emptyImg + leftPPS.imprint" alt="Bild links ohne Fuß"
                                     v-on:click="imageDetails(emptyImg + leftPPS.imprint)">
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr v-if="rightExists">
                <th>
                    Bilder rechts
                    <div v-if="editable">
                        <br>
                        <md-button class="md-raised md-accent" v-on:click="editImages(1)">Bearbeiten</md-button>
                    </div>
                    <div v-if="rightPPS.status === PDFStatus.ready" style="font-weight: normal">
                        <br>
                        PDF in Arbeit
                    </div>
                    <div v-if="rightPPS.status === PDFStatus.done">
                        <br>
                        <md-button class="md-raised md-accent" v-on:click="getPDF(rightPPS)">PDF</md-button>
                    </div>
                    <div v-if="rightPPS.status === PDFStatus.error" style="font-weight: normal">
                        <br>
                        PDF Fehler
                    </div>
                </th>
                <td>
                    <table class="foot_images">
                        <tr>
                            <th>mit Fuß</th>
                            <th>ohne Fuß</th>
                        </tr>
                        <tr>
                            <td width="33%">
                                <img :src="emptyImg + rightPPS.feet" alt="Bild links mit Fuß"
                                     v-on:click="imageDetails(emptyImg + rightPPS.feet)">
                            </td>
                            <td>
                                <img :src="emptyImg + rightPPS.imprint" alt="Bild links ohne Fuß"
                                     v-on:click="imageDetails(emptyImg + rightPPS.imprint)">
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr v-if="leftExists || rightExists">
                <td colspan="2">
                    Für die Detailansicht auf das jeweilige Bild klicken.
                </td>
            </tr>
        </table>

        <br>
        <md-button v-if="paw && (paw.status === PAWStatus.offered)" class="md-raised md-primary" v-on:click="claim()">
            Annehmen
        </md-button>
        <md-button v-if="paw && (paw.status === PAWStatus.offered)" class="md-raised md-accent" v-on:click="reject()">
            Ablehnen
        </md-button>
        <md-button v-if="paw && (paw.status === PAWStatus.offered)" class="md-raised md-accent" v-on:click="rework()">
            Nacharbeiten lassen
        </md-button>

        <md-dialog v-if="paw && imageDetail" :md-active.sync="dialImageDetail">
            <md-dialog-title>
                Detailansicht
            </md-dialog-title>
            <md-dialog-content>
                <img :src="imageDetail" alt="" class="imageDetail">
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised" @click="dialImageDetail = false; imageDetail = null">Schließen!</md-button>
            </md-dialog-actions>
        </md-dialog>


        <Partner_Rework_Reject v-bind:active="rework_reject.active" :action="rework_reject.action"
                               v-bind:paw-id="rework_reject.pawId" @onClose="rework_reject.active = false"/>
    </div>
</template>

<script>
import {dateFormat} from "@/helpers/dates";
import {PAWStatus} from "@/helpers/PAWstates";
import {PDFStatus} from "@/helpers/PDFstates";
import Partner_Rework_Reject from "./Partner_Rework_Reject";

const emptyImg = "data:image/jpeg;base64,";

export default {
    name: 'Partner_PAW_detail',
    props: {},
    components: {
        Partner_Rework_Reject
    },
    data: () => ({
        dialDelete: false,
        dialImageDetail: false,
        deletePAWid: null,
        imageDetail: null,

        rework_reject: {
            active: false,
            action: "",
            pawId: ""
        },
        PAWStatus,
        PDFStatus,
        emptyImg,

        dateFormat: dateFormat
    }),
    computed: {
        commentHTML: function () {
            let com = this.paw.comment.replace(/\r/g, "").replace(/\n/g, "<br>")
            if (com.replace(/ /g, "") === "") {
                return "keine Anmerkungen angegeben"
            } else {
                return com
            }
        },
        leftExists: function () {
            return this.leftPPS && this.leftPPS.feet && this.leftPPS.feet.length > 0 && this.leftPPS.imprint && this.leftPPS.imprint.length > 0
        },
        rightExists: function () {
            return this.rightPPS && this.rightPPS.feet && this.rightPPS.feet.length > 0 && this.rightPPS.imprint && this.rightPPS.imprint.length > 0
        },
        leftPPS: function () {
            if (!this.paw)
                return undefined
            let left
            for (let elem of this.paw.pawPicSets) {
                if (elem.feetType === 0) {
                    left = elem
                }
            }
            return left
        },
        rightPPS: function () {
            if (!this.paw)
                return undefined
            let right
            for (let elem of this.paw.pawPicSets) {
                if (elem.feetType === 1) {
                    right = elem
                }
            }
            return right
        },
        paw: function () {
            return this.$store.state.paw_detail
        },
        gender: function () {
            if (this.paw === null) {
                return ""
            } else {
                switch (this.paw.gender) {
                    case 0:
                        return "männlich"
                    case 1:
                        return "weiblich"
                    case 2:
                        return "divers"
                    default:
                        return ""
                }
            }
        },
        editable: function () {
            return this.paw && (this.paw.status === 1 ||this.paw.status === 3 || this.paw.status === 4 || this.paw.status === 5)
        }
    },
    mounted() {
        const t = this
        const route = "" + t.$router.currentRoute.fullPath.toString()
        this.$store.dispatch("loadPawDetails", this.$route.params.pawId).then(() => {
            let paw = t.$store.state.paw_detail
            if (paw.pawPicSets.length > 0) {
                let left = null
                let right = null
                let enableReload = false
                for (let elem of paw.pawPicSets) {
                    if (elem.feetType === 0) {
                        left = elem
                    }
                }
                if(left && left.status === PDFStatus.ready)
                    enableReload = true
                for (let elem of paw.pawPicSets) {
                    if (elem.feetType === 1) {
                        right = elem
                    }
                }
                if(right && right.status === PDFStatus.ready)
                    enableReload = true

                if(enableReload){
                    console.log("Setting reload-timer")
                    const i = setInterval(function(){
                        try {
                            if(route !== t.$router.currentRoute.fullPath.toString()) {
                                clearInterval(i)
                                console.log("Stopping timer due to route-change")
                                return;
                            }
                            t.$store.dispatch("reloadPawDetails", t.$route.params.pawId).then(() => {
                                t.$nextTick(() => {
                                    if(t.leftPPS && t.leftPPS.status !== PDFStatus.ready
                                        && t.rightPPS && t.rightPPS.status !== PDFStatus.ready) {
                                        clearInterval(i)
                                        console.log("Stopping timer due to PDF-ready")
                                    }
                                })
                            })
                        } catch (err) {
                            clearInterval(i)
                            t.$store.commit("setRXTX", false)
                            console.log("Stopping timer due to error")
                        }
                    }, 10000);
                }
            }
        }, this)
        this.deletePAWid = this.$route.params.pawId;
    },
    methods: {
        reject() {
            this.rework_reject.pawId = this.$route.params.pawId
            this.rework_reject.action = "reject"
            this.rework_reject.active = true
        },
        rework() {
            this.rework_reject.pawId = this.$route.params.pawId
            this.rework_reject.action = "rework"
            this.rework_reject.active = true
        },
        claim() {
            const t = this
            this.$store.dispatch("claimPaw", this.$route.params.pawId).then(() => {
                t.$store.dispatch("showSnackbar", {text: "Auftrag übernommen."})
            })
        },
        reqDeletePAW() {
            this.dialDelete = true;
        },
        deletePAW() {
            this.dialDelete = false;
            this.userdata.deletePAW(this.deletePAWid);
            this.$router.push("/partner/dashboard/")
        },
        imageDetails(img) {
            this.imageDetail = img;
            this.dialImageDetail = true
        },
        editImages(idx) {
            this.$router.push("/partner/paw/" + this.$route.params.pawId + "/editPics/" + idx);
        },
        getPDF(pps){
            let lr = '';
            switch(pps.feetType){
                case 0:
                    lr = 'L';
                    break;
                case 1:
                    lr = 'R';
                    break;
                default:
                    lr = 'S';
            }
            const req = {
                ppsId: pps.id,
                pawId: this.paw.id,
            }
            this.$store.dispatch("getPDF", req).then((result) => {
                let a = document.createElement('a');
                a.download = lr+"_"+dateFormat(new Date, "isoDate")+'_paw_'+this.paw.description+'.pdf';
                a.className = "md-hide"
                a.href = 'data:application/pdf;base64,' + result;
                document.body.appendChild(a)
                a.click();

                this.$store.dispatch("showSnackbar", {text: "PDF wird gespeichert."})
            })
        }
    }
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

.imageDetail {
    height: 100%;
    max-height: 100%;
}

table#table {
    display: inline-block;
    border-collapse: collapse;
    max-width: 90%;
}

table#table > tr > th {
    font-size: large;
    padding: 10px;
}

table#table > tr > td {
    padding: 10px;
}

table#table > tr:nth-child(odd) {
    background: #EEE;
}

table#table > tr:nth-child(even) {
    background: #FFF;
}

table.foot_images {
    width: 100%;
}

table.foot_images > tr > td {
    width: 33%;
}
</style>
