/**
 * Status:
 * 0: created
 * 1: offered
 * 2: rejected
 * 3: claimed
 * 4: rework
 * 5: done
 * 6: imgs added
 */

const PDFStatus = {
    created: 0,
    ready: 1,
    done: 2,
    error: 9,
}

// eslint-disable-next-line no-unused-vars
const codenames = [
    "created",
    "ready",
    "done",
    "error",
]

// eslint-disable-next-line no-unused-vars
const statusList = [
    "initial",
    "config angelegt",
    "PDF erzeugt",
    "Fehler",
]
export {PDFStatus}
